import React from 'react'
// import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { LabeledField } from 'lp-components'
import Cleave from 'cleave.js/react'

const propTypes = {}

const defaultProps = {}

// https://github.com/nosir/cleave.js/issues/344

/* eslint-disable */

function PhoneInput (props) {
  const { input, meta, ...rest } = props
  return (
    <LabeledField { ...props }>
      <Cleave options={{ 
        numericOnly: true,
        blocks: [0, 3, 0, 3, 4],
        delimiters: ['(', ')', ' ', '-'], 
      }} 
      { ...input }
      { ...rest }
      />
    </LabeledField>
  )
}

PhoneInput.propTypes = propTypes
PhoneInput.defaultProps = defaultProps

export default pure(PhoneInput)