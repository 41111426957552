export const states = [
  {
    key: "Alabama",
    value: "AL"
  },
  {
    key: "Alaska",
    value: "AK"
  },
  {
    key: "American Samoa",
    value: "AS"
  },
  {
    key: "Arizona",
    value: "AZ"
  },
  {
    key: "Arkansas",
    value: "AR"
  },
  {
    key: "California",
    value: "CA"
  },
  {
    key: "Colorado",
    value: "CO"
  },
  {
    key: "Connecticut",
    value: "CT"
  },
  {
    key: "Delaware",
    value: "DE"
  },
  {
    key: "District Of Columbia",
    value: "DC"
  },
  {
    key: "Florida",
    value: "FL"
  },
  {
    key: "Georgia",
    value: "GA"
  },
  {
    key: "Guam",
    value: "GU"
  },
  {
    key: "Hawaii",
    value: "HI"
  },
  {
    key: "Idaho",
    value: "ID"
  },
  {
    key: "Illinois",
    value: "IL"
  },
  {
    key: "Indiana",
    value: "IN"
  },
  {
    key: "Iowa",
    value: "IA"
  },
  {
    key: "Kansas",
    value: "KS"
  },
  {
    key: "Kentucky",
    value: "KY"
  },
  {
    key: "Louisiana",
    value: "LA"
  },
  {
    key: "Maine",
    value: "ME"
  },
  {
    key: "Maryland",
    value: "MD"
  },
  {
    key: "Massachusetts",
    value: "MA"
  },
  {
    key: "Michigan",
    value: "MI"
  },
  {
    key: "Minnesota",
    value: "MN"
  },
  {
    key: "Mississippi",
    value: "MS"
  },
  {
    key: "Missouri",
    value: "MO"
  },
  {
    key: "Montana",
    value: "MT"
  },
  {
    key: "Nebraska",
    value: "NE"
  },
  {
    key: "Nevada",
    value: "NV"
  },
  {
    key: "New Hampshire",
    value: "NH"
  },
  {
    key: "New Jersey",
    value: "NJ"
  },
  {
    key: "New Mexico",
    value: "NM"
  },
  {
    key: "New York",
    value: "NY"
  },
  {
    key: "North Carolina",
    value: "NC"
  },
  {
    key: "North Dakota",
    value: "ND"
  },
  {
    key: "Ohio",
    value: "OH"
  },
  {
    key: "Oklahoma",
    value: "OK"
  },
  {
    key: "Oregon",
    value: "OR"
  },
  {
    key: "Pennsylvania",
    value: "PA"
  },
  {
    key: "Puerto Rico",
    value: "PR"
  },
  {
    key: "Rhode Island",
    value: "RI"
  },
  {
    key: "South Carolina",
    value: "SC"
  },
  {
    key: "South Dakota",
    value: "SD"
  },
  {
    key: "Tennessee",
    value: "TN"
  },
  {
    key: "Texas",
    value: "TX"
  },
  {
    key: "Utah",
    value: "UT"
  },
  {
    key: "Vermont",
    value: "VT"
  },
  {
    key: "Virgin Islands",
    value: "VI"
  },
  {
    key: "Virginia",
    value: "VA"
  },
  {
    key: "Washington",
    value: "WA"
  },
  {
    key: "West Virginia",
    value: "WV"
  },
  {
    key: "Wisconsin",
    value: "WI"
  },
  {
    key: "Wyoming",
    value: "WY"
  }
]
