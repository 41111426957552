import { handleActions } from 'redux-actions'
import { selectorForSlice, setState, } from 'lp-redux-utils'
import * as actions from './actions'
import { createSelector } from 'reselect'

const reducerKey = 'home'
const slice = 'root.home'

const initialState = {
  allowedEntry: !(process.env.COUNTDOWN || process.env.COUNTDOWN_SERVER_URL),
}

const reducer = handleActions({
  [actions.setAllowedEntry]: setState('allowedEntry'),
  [actions.setIsLoaded]: setState('timeIsLoaded'),
}, initialState)

const select = selectorForSlice(slice)

const selectors = {
  allowedEntry: select('allowedEntry'),
  timeIsLoaded: select('timeIsLoaded'),
}

selectors.isLoaded = createSelector(
  [selectors.allowedEntry, selectors.timeIsLoaded],
  function (allowedEntry, timeIsLoaded) {
    if (timeIsLoaded) return true
    if (process.env.COUNTDOWN) return true
    if (process.env.COUNTDOWN_SERVER_URL) return
    return allowedEntry !== undefined
  }
)

export { reducer, selectors, reducerKey }
