import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'redux'
import {
  FieldArray,
  propTypes as formPropTypes,
} from 'redux-form'
import { scroller } from 'react-scroll'
import first from 'lodash/first'
import { lpForm } from 'lp-form'
import { modifyProps, } from 'lp-hoc'
import {
  ButtonArea,
  Checkbox,
  Input,
  Select,
  SubmitButton,
} from 'lp-components'
import { PhoneInput } from 'components'
import { ScrollField as Field, Countdown, } from '../components'
import ChildrenFields from './ChildrenFields'
import { validateNested, nestedKeys, persistFormValues } from 'utils'
import * as options from 'options'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

const handleChange = () => {
  setTimeout(() => {
    const data = {}
    const elements = document.querySelector('form').elements
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]
      data[element.name] = element.value
    }
    persistFormValues(data)
  }, 50)
}

function ReservationForm ({ handleSubmit, submitting, error, allowedEntry, timeRemaining }) {
  return (
    <form autoComplete="off" onSubmit={ handleSubmit } onChange={ handleChange } disabled={ !allowedEntry }>
      <input type="hidden" name="doNotSubmit" />
      {
        error &&
        <p className="full-error-message">{ error }</p>
      }
      <div className="row">
        <Field
          id="parentFirstName"
          name="parentFirstName"
          label="Parent/Guardian First Name "
          hint="(Primer Nombre del padre/guardián)"
          component={ Input }
          autoComplete="parentFirstName"
          />
        <Field
          id="parentLastName"
          name="parentLastName"
          label="Parent/Guardian Last Name "
          hint="(Apellido del padre/guardián)"
          component={ Input }
          autoComplete="parentLastName"
        />
        <Field
          id="phoneNumber"
          name="phoneNumber"
          label="Phone Number "
          type="tel"
          hint="(Número de teléfono)"
          component={ PhoneInput }
          autoComplete="phoneNumber"
          placeholder="(XXX) XXX-XXXX"
        />
        <div className="checkbox-wrap align-checkbox">
          <Field
            id="isMobilePhone"
            name="isMobilePhone"
            label="Is this a mobile phone? "
            hint="(¿Es un teléfono móvil?)"
            component={ Checkbox }
            className="not-required"
          />
        </div>
      </div>
      <div className="row">
        <Field
          name="emailAddress"
          label="Email Address "
          hint="(Correo electrónico)"
          component={ Input }
          autoComplete="emailAddress"
          type='email'
        />
        <Field
          name="confirmEmailAddress"
          label="Confirmation of Email Address "
          hint="(Confirmación de correo electrónico)"
          component={ Input }
          autoComplete="confirmEmailAddress"
          type='email'
        />
      </div>
      <div className="row">
        <Field
          name="addressLine1"
          label="Address Line 1 (street address) "
          hint="(Línea 1 Domicilio [Número y calle])"
          component={ Input }
          autoComplete="addressLine1"
        />
        <Field
          name="addressLine2"
          label="Address Line 2 (apartment, suite, unit, building, floor, etc.) "
          hint="(Línea 2 Domicilio [apartamento, unidad, edificio, piso, etc.])"
          component={ Input }
          autoComplete="parentLastName"
          className="not-required"
        />
        <div className="col-4">
          <Field
            name="city"
            label="City "
            hint="(Ciudad)"
            component={ Input }
            autoComplete="city"
          />
          <Field
            name="state"
            label="State "
            hint="(Estado)"
            component={ Select }
            options={ options.states }
            placeholder="Select"
          />
          <Field
            name="zipCode"
            label="Zip Code "
            hint="(Código Postal)"
            component={ Input }
            autoComplete="zip"
            maxLength={ 5 }
          />
        </div>
      </div>
      <div className="row">
        <FieldArray
          name="children"
          component={ ChildrenFields }
        />
      </div>
      <div className="row">
        <p>
          The following boxes are required for the reservation submission to be considered eligible:
          <br></br><i>(Las casillas marcadas con un asterisco (*) son obligatorias para que el envío de la reserva se considere elegible:)</i>
        </p>
        <div className="checkbox-wrap">
          <Field
            label="Agree"
            hint="([marque la caja] Acepto)"
            name="agree1"
            component={ Checkbox }
          />
          <label htmlFor="agree1" className="separate-label">
            I hereby confirm that I am the parent or custodian of the child(ren) listed on this form, and I hereby authorize Empower Illinois to access information needed for income eligibility determinations under the Tax Credit scholarship program.
            <br></br><br></br><i>(Confirmo que soy el padre/la madre o el tutor/la tutora de los menores indicados en este formulario y autorizo a Empower Illinois a acceder a la información necesaria para las determinaciones de elegibilidad según los ingresos de acuerdo con el programa de becas por crédito fiscal.)</i>
          </label>
        </div>
        <div className="checkbox-wrap">
          <Field
            label="Agree"
            hint="([marque la caja] Acepto)"
            name="agree2"
            component={ Checkbox }
          />
          <label htmlFor="agree2" className="separate-label">
            I declare that the information on this form is, to the best of my knowledge, correct and complete. I agree, if requested, to send additional information to support statements on this form. By submitting this application, I also acknowledge that I have read and agree to the terms of the <a href="https://empowerillinois.org/terms/" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> and <a href="https://empowerillinois.org/privacy/" target="_blank" rel="noopener noreferrer">Empower Illinois Privacy Policy</a>. Under this policy, among other things, pursuant to the Illinois <i>Invest in Kids Act</i>, I authorize Empower Illinois, its service providers, and others as permitted by law to access information needed for income eligibility determinations. I also acknowledge that Empower Illinois may share the information it collects, including names, contact information, and all other information provided by me on this application, with its service providers, schools listed on the application and related school systems, and others as required by law under the Illinois <i>Invest in Kids Act</i>, in order to administer scholarship programs and make income eligibility determinations.
            <br></br><br></br><i>(Declaro que la información indicada en este formulario es, a mi entender, correcta y completa. Acepto, de solicitarse, enviar información adicional para respaldar las declaraciones realizadas en el formulario. Al enviar esta solicitud, también reconozco que he leído y acepto los <a href="https://empowerillinois.org/terms/" target="_blank" rel="noopener noreferrer">Terminos y las condiciones</a> y <a href="https://empowerillinois.org/privacy/" target="_blank" rel="noopener noreferrer">la Política de privacidad de Empower Illinois</a>. De acuerdo con esta política, entre otras cosas, conforme a la Ley de inversión en los niños de Illinois (Illinois Invest in Kids Act), autorizo a Empower Illinois, los proveedores de servicios y otros, según lo permita la ley, a acceder a la información necesaria para las determinaciones de elegibilidad según los ingresos. Asimismo, reconozco que Empower Illinois podrá compartir la información recopilada, incluso los nombres, la información de contacto y toda otra información brindada por mí mediante esta solicitud, con los proveedores de servicios, las escuelas indicadas en la solicitud y los sistemas educativos relacionados, y otros según lo exija la ley conforme a la Ley de inversión en los niños de Illinois, para administrar los programas de becas y realizar las determinaciones de elegibilidad según los ingresos.)</i>
          </label>
        </div>
        <div className="checkbox-wrap">
          <Field
            label="Agree"
            hint="([marque la caja] Acepto)"
            name="agree3"
            component={ Checkbox }
            className="not-required bold"
          />
          <label htmlFor="agree3" className="not-required separate-label">
          I authorize Empower Illinois to contact me by email and/or text message with additional information related to the mission of Empower Illinois, including information about how parents, grandparents, and guardians can be involved to support making this scholarship possible. My consent or lack of consent will have no effect on my scholarship eligibility.
            <br></br><br></br><i>(Autorizo a Empower Illinois a comunicarse conmigo por correo electrónico y/o mensaje de texto para enviar información adicional sobre la misión de Empower Illinois, incluso información sobre cómo los padres, los abuelos y los tutores pueden involucrarse para que la beca sea posible. Mi consentimiento o falta de consentimiento no afectará de manera alguna la elegibilidad de mi beca.)</i>
          </label>
        </div>
      </div>
      <div className="">
        { !allowedEntry && timeRemaining && <Countdown time={ timeRemaining } /> }
        <ButtonArea>
          <SubmitButton { ...{ submitting } } id="submitButton" className={ `${allowedEntry ? '' : 'is-disabled' }`}>
            <strong>Continue</strong> <br />
            <i>(Seguir)</i>
          </SubmitButton>
        </ButtonArea>
      </div>
    </form>
  )
}

ReservationForm.propTypes = propTypes
ReservationForm.defaultProps = defaultProps

function modify ({ onSubmitFail }) {
  return {
    onSubmitFail: (params, dispatch, _, props) => {
      const errorKeys = nestedKeys(props.syncErrors) 
      if (errorKeys.length > 0) {
        const firstErrorField = first(errorKeys).slice(0, -3) // remove last 3 characters since there will be an unecessary [0] at the end
        scroller.scrollTo(firstErrorField, { smooth: true, offset: -150 }, )
      }
      if (onSubmitFail) return onSubmitFail(params, dispatch, _, props)
    }
  }
}

export default compose(
  modifyProps(modify),
  lpForm({
    name: 'empower-reservation',
    validate: validateNested({
      'parentFirstName': {
        presence: { message: "can't be blank (El primer nombre del padre/madre no debe estar en blanco)" }
      },
      'parentLastName': {
        presence: { message: "can't be blank (El apellido del padre/madre no debe estar en blanco)" }
      },
      'phoneNumber': {
        presence: { message: "can't be blank (El numero telefonico no debe estar en blanco)" },
        length: { is: 14, message: "is invalid (El numero telefonico no es válido" }
      },
      'emailAddress': {
        presence: { message: "can't be blank (El correo electrónico no debe estar en blanco)" },
        email: {
          message: "is not a valid email (El domicilio del correo electrónico no es válido)" 
        },
      },
      'confirmEmailAddress': {
        presence: { message: "can't be blank (Confirme su domicilio del correo electrónico)" },
        equality: {
          attribute: 'emailAddress',
          message: "^ Email addresses do not match (Las direcciones de correo no coinciden)",
          comparator: function(emailAddress, confirmEmailAddress) {
            return (emailAddress || '').toLowerCase() === (confirmEmailAddress || '').toLowerCase()
          }
        },
      },
      'addressLine1': { presence: { message: "can't be blank (no debe estar en blanco)" }},
      'city': { presence: { message: "can't be blank (no debe estar en blanco)" }},
      'state': { presence: { message: "can't be blank (no debe estar en blanco)" }},
      'zipCode': {
        presence: { message: "can't be blank (no debe estar en blanco)" },
        numericality: { onlyInteger: true },
        length: { is: 5 },
      },
      'agree1': {
        presence: { message: "^Must be accepted (debe ser aceptado)" },
        inclusion: {
          within: [true],
          message: "^Must be accepted (debe ser aceptado)"
        }
      },
      'agree2': {
        presence: { message: "^Must be accepted (debe ser aceptado)" },
        inclusion: {
          within: [true],
          message: "^Must be accepted (debe ser aceptado)"
        }
      },
      'children[].firstName': { presence: { message: "can't be blank (no debe estar en blanco)" }},
      'children[].lastName': { presence: { message: "can't be blank (no debe estar en blanco)" }},
    }),
  }),
)(ReservationForm)
