import React from 'react'
// import PropTypes from 'prop-types'

const propTypes = {}

const defaultProps = {
  meta: {
    error: [],
  }
}

function RecaptchaInput () {
  return (
    <div>
      <div
        className="g-recaptcha"
        data-sitekey={ process.env.GOOGLE_RECAPTCHA_KEY }
      />
    </div>
  )
}

RecaptchaInput.propTypes = propTypes

RecaptchaInput.defaultProps = defaultProps

export default RecaptchaInput
