import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route } from 'react-router'
import Layout from './Layout'
import { routes as HomeRoutes } from './home'
import history from 'browser-history'
import store from 'store'

const propTypes = {}

const defaultProps = {}

function Routes (){
  return (
    // React Redux `Provider` component to enable the `connect` function to connect to the Redux store.
    <Provider store={ store }>
      <ConnectedRouter history={ history } >
        <Layout>
          <Route component={ HomeRoutes } />
        </Layout>
      </ConnectedRouter>
    </Provider>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
