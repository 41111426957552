import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'redux'
import {
  propTypes as formPropTypes,
} from 'redux-form'
import { lpForm } from 'lp-form'
import {
  ButtonArea,
  SubmitButton,
} from 'lp-components'
import { ScrollField as Field, RecaptchaInput } from '../components'
import { onMount } from 'lp-hoc'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

const { RECAPTCHA_ENABLED } = process.env

function ConfirmationForm ({ handleSubmit, submitting, }) {
  return (
    <form onSubmit={ handleSubmit }>
      {
        RECAPTCHA_ENABLED &&
        <div style={{ paddingTop: '20px' }}>
          <Field
            name="recaptcha"
            label={ false }
            component={ RecaptchaInput }
          />
        </div>
      }
      <ButtonArea>
        <SubmitButton { ...{ submitting } } id="submitButton">
          <strong>Submit</strong> <br />
          <i>(Someta)</i>
        </SubmitButton>
      </ButtonArea>
      <p className="center"><strong>Submit to complete your reservation.</strong>
      <br></br><i>(Someta para completar su reservación.)</i></p>
    </form>
  )
}

ConfirmationForm.propTypes = propTypes
ConfirmationForm.defaultProps = defaultProps

function onComponentDidMount() {
  if (!RECAPTCHA_ENABLED) return
  const script = document.createElement('script')
  script.src = 'https://www.google.com/recaptcha/api.js'
  script.async = true
  script.defer = true
  document.body.appendChild(script)
}

export default compose(
  onMount(onComponentDidMount),
  lpForm({
    name: 'confirmation-form',
  }),
)(ConfirmationForm)
