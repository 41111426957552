import uuid from 'uuid/v1'
import deepMap from './deepMap'
import { isObject, isArray } from 'lodash'

const isPlainObject = obj => isObject(obj) && !isArray(obj)

// Adds an id to an object, as well as all nested objects
function addIdsToNestedObject (obj) {
  return deepMap(obj, value => isPlainObject(value) ? { ...value, id: uuid() } : value)
}

export default addIdsToNestedObject
