import React from 'react'
import { Field } from 'redux-form'
import { Element } from 'react-scroll'

// Wraps a redux-form Field with a react-scroll Element with the same name

// eslint-disable-next-line react/prop-types
function ScrollField ({ name, ...rest }) {
  return (
    <Element name={ name } className="fieldset-wrap">
      <Field { ...{ name, ...rest } } />
    </Element>
  )
}

export default ScrollField