import React from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  fieldPropTypes,
} from 'lp-components'
import { ScrollField as Field } from '../components'

const propTypes = {
  fields: PropTypes.object,
  ...fieldPropTypes,
}

const defaultProps = {}

function ChildrenFields ({ fields, meta: { error, submitFailed } }) {
  return (
    <div className="add-children">
      {
        fields.map((child, index) => (
          <div key={ index } className="child-block">
            {
              index === 0 &&
              <p>
                <strong>Note: Order of students on this page is the order in which scholarships will be awarded, subject to the availability of funds.</strong>
                <br></br><i>(NOTA: Las becas se otorgarán conforme al orden de estudiantes en esta pagina, siempre y cuando haya fondos disponibles.)</i>
              </p>
            }
            <h4>Child #{ index + 1 }</h4>
            <p className="es left"><i>(Hijo/a #{ index + 1 })</i></p>
            <div className="col-6">
              <Field
                name={ `${ child }.firstName` }
                label="First Name "
                hint="(Primer nombre)"
                component={ Input }
                autoComplete="childFirstName"
              />
              <Field
                name={ `${ child }.lastName` }
                label="Last Name "
                hint="(Apellido)"
                component={ Input }
                autoComplete="childLastName"
              />
            </div>
            {
              index !== 0 &&
              <div style={{ padding: 15 }} onClick={ () => fields.remove(index) } className="alert-link">
              × Remove Child (Retire hijo/a)
              </div>
            }
          </div>
        ))
      }
      <div>
        <button
          type="button"
          className="button-primary child-button"
          onClick={ () => fields.push({}) }
        >
          Add Another Child (Añadir otro hijo/a)
        </button>
        { submitFailed && error && <span>{ error }</span> }
        <div className="divider"></div>
      </div>
    </div>
  )
}

ChildrenFields.propTypes = propTypes
ChildrenFields.defaultProps = defaultProps

export default ChildrenFields
