import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {  onUnmount } from 'lp-hoc'
import * as firebaseSvc from 'firebase-service'
// import { ToggleDeadlineForm } from './forms'
import { selectors } from './reducer'

const {
  HELPLINE_PHONE_DISPLAY,
  HELPLINE_PHONE,
  HELPLINE_EMAIL
} = process.env

const propTypes = {
  children: PropTypes.node.isRequired,
  isLoaded: PropTypes.bool,
}
const defaultProps = {}

function Layout ({ children, isLoaded, }) {
  return (
    <div>
      { children }
      { isLoaded &&
        <footer>
          <p className="center">Family Scholarship Helpline: <a target="_blank" rel="noopener noreferrer" href={ "tel:" + HELPLINE_PHONE }>{ HELPLINE_PHONE_DISPLAY }</a> or <a target="_blank" rel="noopener noreferrer" href={ "mailto:" + HELPLINE_EMAIL }>{ HELPLINE_EMAIL }</a></p>
        </footer>
      }
    </div>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps (state) {
  return {
    isLoaded: selectors.isLoaded(state),
  }
}

export default compose(
  connect(mapStateToProps, null),
  onUnmount(() => firebaseSvc.closeFirebaseConnection()),
)(Layout)
