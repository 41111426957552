// import * as firebase from 'firebase'

function allowedEntry() {
  // const timestamp = firebase.database.ServerValue.TIMESTAMP
  // firebase.database().ref('/.info/serverTimeOffset')
  // .once('value')
  // .then(function stv(data) {
  //   const dateNum = data.val() + Date.now()
  //   console.log(new Date(dateNum))
  // }, function (err) {
  //   console.log(err)
  //   return err;
  // });
  return false
}

export default allowedEntry
