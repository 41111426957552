import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as routerActions from 'connected-react-router'
import * as firebase from 'firebase-service'
import * as flashActions from 'redux-flash'
import {  modifyProps } from 'lp-hoc'
import { stringify } from 'query-string'
import logoImg from 'images/logo.svg'
import { ConfirmationForm } from '../forms'
import { selectors } from '../reducer'
import { 
  fetchFormValues,
  persistFormValues,
  redirectUnless,
} from 'utils'

const propTypes = {
  push: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  submission: PropTypes.object,
}

const defaultProps = {}

function Summary ({ push, flashErrorMessage, submission }) {
  return (
    <div className="reservation-page">
      <header>
        <img src={ logoImg } alt="Empower Illinois" className="logo" />
        <div className="text-block">
          <h1>Tax Credit Scholarship Reservation</h1>
          <p className="es"><i>(Reservación para la beca)</i></p>
        </div>
      </header>
      <div className="progress-bar">
        <ol>
          <li className="active"><span>1</span></li>
          <li className="active"><span>2</span></li>
          <li><span>3</span></li>
          <div className="progress-line halfway"></div>
        </ol>
      </div>
      <div className="content-block-container">
        <div className="warning-message">
          <h2 className="center"><strong>Attention! You are not finished yet!</strong></h2>
          <p className="es"><i>(¡Atención! ¡Aún no has terminado!)</i></p>
        </div>
        <p className="center"><strong>You must click submit before closing this page in order to receive a timestamp.</strong></p>
        <p className="es"><i>(Debe completar esta página para que sea revisada su reservación y para después poder ser invitado/a a participar en el proceso entero de aplicación de Empower Illinois.)</i></p>
        <br></br>
        <div className="read-only">
          <p><strong>Parent Details</strong> <i>(Detalles de los padres)</i></p>
          <p>{ submission.parentFirstName } { submission.parentLastName }<br/>{ submission.phoneNumber }<br/>{ submission.emailAddress }</p>
          <p>{ submission.addressLine1 }<br/>{ submission.addressLine2 }<br/>{ submission.city }, {submission.state }, { submission.zipCode }</p>
          <hr/>
          <p><strong>Student Details</strong> <i>(Detalles del estudiante)</i></p>
          <ul>
            { submission.children.map((child, i) => (<li key={ i }>{ child.firstName } { child.lastName }</li>) )}
          </ul>
        </div>
        <ConfirmationForm 
          onSubmit={ firebase.submitReservation }
          onSubmitSuccess={ (confirmationInfo) => {
            persistFormValues({})
            firebase.closeFirebaseConnection()
            return push('/confirmation?' + stringify({ confirmationInfo: JSON.stringify(confirmationInfo) }))
          }}
          onSubmitFail={ () => flashErrorMessage('Please ensure you have accepted the Captcha and it is after 7 PM CST.<br/><em>(Por favor asegure que ha aceptado el CAPTCHA y que sea después de las 7 PM CST.)</em>') }
        />
      </div>
    </div>
  )
}

Summary.propTypes = propTypes
Summary.defaultProps = defaultProps

function mapStateToProps (state) {
  return {
    allowedEntry: selectors.allowedEntry(state),
  }
}

const mapDispatchToProps = {
  push: routerActions.push,
  flashErrorMessage: flashActions.flashErrorMessage,
}

function modify(){
  return {
    submission: fetchFormValues()
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify),
  redirectUnless('allowedEntry', '/'),
)(Summary)
