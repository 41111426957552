import { compose } from 'redux'
import { connect } from 'react-redux'
import * as routerActions from 'connected-react-router'
import { onMount, waitFor } from 'lp-hoc'
import { get } from 'lodash/fp'

// Redirect unless prop is truthy
function redirectUnless (key, path='/') {
  return compose(
    connect(null, { push: routerActions.push }),
    onMount(props => { if (!get(key, props)) return props.push(path) }),
    waitFor(key, () => null),
  )
}

export default redirectUnless
