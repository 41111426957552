import { PERSISTENCE_KEY } from 'config'

const castToBool = (json) => {
  ['agree1', 'agree2', 'agree3', 'isMobilePhone'].forEach((key) => {
    json[key] = json[key] === 'true' ? true : false
  })
}

const castToNested = (json) => {
  const nestedKey = 'children['
  Object.keys(json).forEach((key) => {
    if (key === '') delete json[key]
    if (!key.includes(nestedKey)) return
    const children = json.children || []
    const index = key.slice(nestedKey.length, nestedKey.length + 1)
    const attrName = key.split('.')[1]
    const child = children[index] || {}
    child[attrName] = json[key]
    children[index] = child
    delete json[key]
    json.children = children
  })
}

function fetchFormValues () {
  const formValues = JSON.parse(localStorage.getItem(PERSISTENCE_KEY) || '{}');
  castToBool(formValues)
  castToNested(formValues)
  return formValues
}

export default fetchFormValues
