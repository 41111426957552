import React from 'react'
import PropTypes from 'prop-types'
import { flashMessageType } from 'redux-flash'
import FlashMessage from './FlashMessage'

const propTypes = {
  messages: PropTypes.arrayOf(flashMessageType).isRequired,
  limit: PropTypes.number,
}

const defaultProps = {
  limit: 5,
}

function FlashMessageContainer ({ messages, limit, ...rest }) {
  const messagesToDisplay = messages.slice(0, limit)
  return (
    <div className="flash-message-container" role="alert">
    {
      messagesToDisplay.map(message => 
        <FlashMessage key={ message.id } message={ message } { ...rest } />
      )
    }
    </div>
  )
}

FlashMessageContainer.propTypes = propTypes
FlashMessageContainer.defaultProps = defaultProps

export default FlashMessageContainer
