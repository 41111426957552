import { withProps, wrapDisplayName } from 'recompose'
import { noop, mapValues, isFunction, pick } from 'lodash'
import coerce from './coerce'
import { parse } from 'query-string'
import { camelizeKeys } from 'humps'

// Mapping config be a function, string or array of strings.
// String arguments are interpreted as the names of props to pull from params.
const createMapperFunc = (mappingConfig) => {
  if (isFunction(mappingConfig)) return mappingConfig
  return function map (values) {
    return pick(values, mappingConfig)
  }
}

// Accepts a function to map query string params to props
function connectQuery (mappingConfig=noop, { camelize=true }={}) {
  function modify (props) {
    const query = parse(window.location.search)
    const casedQuery = camelize ? camelizeKeys(query) : query
    const primitiveQuery = mapValues(casedQuery, coerce)
    const mapperFunc = createMapperFunc(mappingConfig)
    return mapperFunc(primitiveQuery, props)
  }
  return Wrapped => {
    const Wrapper = withProps(modify)(Wrapped)
    Wrapper.displayName = wrapDisplayName('connectQuery', Wrapper)
    return Wrapper
  }
}

export default connectQuery