import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as actions from '../actions'
import { selectors } from '../reducer'

const propTypes = {
  allowedEntry: PropTypes.bool,
  setAllowedEntry: PropTypes.func.isRequired,
}

const defaultProps = {
  allowedEntry: false
}

function ToggleDeadlineForm ({ allowedEntry, setAllowedEntry, }) {
  const buttonCopy = allowedEntry ? 'Post-Deadline' : 'Pre-Deadline'
  return (
    <a
      className="code"
      href=""
      style={{ position: 'fixed', left: '20px', top: '200px' }}
      onClick={ (e) => {
        e.preventDefault()
        setAllowedEntry(!allowedEntry)
      }}
    >
      { buttonCopy }
    </a>
  )
}

ToggleDeadlineForm.propTypes = propTypes

ToggleDeadlineForm.defaultProps = defaultProps

function mapStateToProps(state){
  return {
    allowedEntry: selectors.allowedEntry(state),
  }
}

const mapDispatchToProps = {
  setAllowedEntry: actions.setAllowedEntry,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ToggleDeadlineForm)
