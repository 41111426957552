import { mapValues, map, isObject, isArray } from 'lodash'

// Map an object or array
function mapCollection (collection, func) {
  const method = isArray(collection) ? map : mapValues
  return method(collection, func)
}

function recursiveDeepMap (collection, iteratee) { 
  // Base case
  if (!isObject(collection)) return collection
  const mappedCollection = mapCollection(collection, value => recursiveDeepMap(value, iteratee))
  return iteratee(mappedCollection)
}

// A custom deep map that runs an iteratee on EVERY nested value of an object 
// - not just the leaves.
// Also note that this function recurses before calling the iteratee.
function deepMap (collection, iteratee) {
  return recursiveDeepMap(collection, iteratee)
}

export default deepMap
