import { forEach, isObject, isNumber } from 'lodash'

// Given an object, return an array of the fuill path of every nested key of that object
// Eg. nestedKeys({ foo: { bar: true }, baz: false, bing: ['bop'] }) -> [ 'foo.bar', 'baz', 'bing[0]' ]

function joinKeys (keyA, keyB) {
  if (!keyA) return keyB
  return isNumber(keyB)
    ? `${ keyA }[${ keyB }]`
    : `${ keyA }.${ keyB }`
}

function recursiveNestedKeys (obj, currentPath='') {
  const keys = []
  forEach(obj, (value, key) => {
    const fullKey = joinKeys(currentPath, key)
    if (isObject(value)) return keys.push(...recursiveNestedKeys(value, fullKey))
    return keys.push(fullKey)
  })
  return keys
}

function nestedKeys (obj) {
  return recursiveNestedKeys(obj)
}

export default nestedKeys