import { validate } from 'lp-form'
import map from 'lodash/map'
import merge from 'lodash/merge'
import first from 'lodash/first'
import exists from 'lodash/identity'

// Validate nested properties using array notation
// Given an object of { key: constraint } pairs, returns a validation function
function validateNested (constraints) {
  return function validate (values) {
    // Validate every constraint separately
    const errors = map(constraints, (constraint, path) => validateRecursive(values, path, constraint))
    return merge(...errors)
  }
}

// Called internally by validateNested.
// Given a values object, a path and a constraint, returns a { key: error } object,
// or undefined if no errors were found.
function validateRecursive (values, path, constraint) {
  // Base case is when key is one level deep - just validate the value
  if (depth(path) === 1) return validate({ [path]: constraint })(values) || {}
  // Otherwise, drill into the object and recursively validate
  const isArrayPath = isArrayKey(getFirstKey(path))
  const key = removeArrayNotation(getFirstKey(path))
  const nestedValue = values[key]
  const restOfPath = getNestedPath(path)
  const errors = isArrayPath
    ? validateArray(nestedValue, restOfPath, constraint)
    : validateRecursive(nestedValue, restOfPath, constraint)
  return errors ? { [key]: errors } : {}
}

// Recursively validates values of an array
function validateArray (array, path, constraint) {
  if (!array) return []
  return array.map((value) => validateRecursive(value, path, constraint)).filter(exists)
}

/* HELPERS */

// Returns the depth of a dot-notation path
function depth (path) {
  return path.split('.').length
}

// Get the first key in a dot-notation path
function getFirstKey (path) {
  return first(path.split('.'))
}

// Get the path at a given level of nesting (default=1)
// Eg getNestedPath('first.second.third') -> 'second.third'
function getNestedPath (path, levels=1) {
  return path.split('.').slice(levels).join('.')
}

// Remove empty brackets from a dot notation string
function removeArrayNotation (path) {
  return path.replace('[]', '')
}

// Returns true if a key ends with brackets
function isArrayKey (key) {
  return key.slice(-2) === '[]'
}

export default validateNested
