import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  time: PropTypes.string,
}

const defaultProps = {
  meta: {
    error: [],
  }
}

function Countdown ({ time }) {
  return (
    <div className="countdown">
      <div className="countdown-inner">
        <div className="text-block">
          <p><strong>Time until next step becomes available</strong></p>
          <p className="es"><i>(Tiempo hasta que el siguiente paso esté disponible)</i></p>
        </div>
        <p className="counter">{ time }</p>
      </div>
    </div>
  )
}

Countdown.propTypes = propTypes

Countdown.defaultProps = defaultProps

export default Countdown
