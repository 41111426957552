import React from 'react'
import PropTypes from 'prop-types'
import { flashMessageType } from 'redux-flash'
import classnames from 'classnames'

const propTypes = {
  message: flashMessageType.isRequired,
  onDismiss: PropTypes.func,
}

const defaultProps = {
  onDismiss: null,
}

function FlashMessage ({ message, onDismiss }) {
  const statusClass = message.isError ? 'failure' : 'success'
  return (
    <div { ...message.props } className={ classnames('flash-message', statusClass, message.props.className) }>
      { 
        onDismiss &&
        <button type="button" className="dismiss" onClick={ () => onDismiss(message) }>
          ×
        </button>
      }
      <p dangerouslySetInnerHTML={ { __html: message.message } }></p>
    </div>
  )
}

FlashMessage.propTypes = propTypes
FlashMessage.defaultProps = defaultProps

export default FlashMessage
