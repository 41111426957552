import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as routerActions from 'connected-react-router'
import * as firebase from 'firebase-service'
import * as flashActions from 'redux-flash'
import { ReservationForm } from '../forms'
import logoImg from 'images/logo.svg'
import { selectors } from '../reducer'
import {
  getSet,
  onMount,
  modifyProps,
  waitFor,
  onUnmount,
} from 'lp-hoc'
import { padStart, } from 'lodash'
import { fetchFormValues } from 'utils'
import * as actions from '../actions'
import { getSecondsRemaining } from '../../../services/countdown'

const propTypes = {
  push: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  allowedEntry: PropTypes.bool,
  timeRemaining: PropTypes.string,
  initialValues: PropTypes.object,
}

const defaultProps = {}
let timer

const DEFAULT_FORM_VALUES = { children: [{}], state: 'IL' }

function Reservation ({ push, flashErrorMessage, allowedEntry, timeRemaining, initialValues, }) {
  return (
    <div className="reservation-page">
      <header>
        <img src={ logoImg } alt="Empower Illinois" className="logo" />
        <div className="text-block">
          <h1>Tax Credit Scholarship Reservation</h1>
          <p className="es"><i>(Reservación para la beca)</i></p>
        </div>
      </header>
      <div className="progress-bar">
        <ol>
          <li className="active"><span>1</span></li>
          <li><span>2</span></li>
          <li><span>3</span></li>
          <div className="progress-line"></div>
        </ol>
      </div>
      <div className="content-block-container">
        { !allowedEntry && 
          <div className="flash-message failure">
            <p className="center"><strong>Please Note - You can fill out this page but will only be able to move on to the next step after 7pm CST.</strong></p>
            <p className="es"><i>(Tenga en cuenta: puede completar esta página, pero solo podrá pasar al siguiente paso después de las 7 p.m. CST.)</i></p>
          </div>
        }
        <p className="center"><strong>You must complete this page in order to have your submission screened and to be invited to participate in the full Empower Illinois application process.</strong></p>
        <p className="es"><i>(Debe completar esta página para que sea revisada su reservación y para después poder ser invitado/a a participar en el proceso entero de aplicación de Empower Illinois.)</i></p>
        <br></br>
        <ReservationForm
          onSubmit={ firebase.saveReservation }
          onSubmitSuccess={ () => {
            return push('/summary')
          }}
          allowedEntry={ allowedEntry }
          timeRemaining={ timeRemaining }
          onSubmitFail={ () => flashErrorMessage('There was a problem submitting your reservation. Please review this page and try again.<br/><em>(Hay un problema con su reservación, favor revise esta página de intentar otra vez.)</em>') }
          initialValues={ initialValues }
        />
      </div>
    </div>
  )
}

Reservation.propTypes = propTypes
Reservation.defaultProps = defaultProps

function mapStateToProps (state) {
  return {
    allowedEntry: selectors.allowedEntry(state),
    isLoaded: selectors.isLoaded(state),
  }
}

const mapDispatchToProps = {
  push: routerActions.push,
  flashErrorMessage: flashActions.flashErrorMessage,
  setAllowedEntry: actions.setAllowedEntry,
  setIsLoaded: actions.setIsLoaded,
}

function onComponentDidMount({ setTimeRemaining, setAllowedEntry, allowedEntry, setIsLoaded, }){
  const getTime = () => {
    getSecondsRemaining().then((secondsRemaining) => {
      setIsLoaded(true)
      if (secondsRemaining <= 0){
        setAllowedEntry(true)
        if (timer) clearInterval(timer)
        return
      }
      const minutes = Math.floor(secondsRemaining / 60)
      const seconds = secondsRemaining - minutes * 60
      setTimeRemaining(`${padStart(minutes, 2, '0')}:${padStart(seconds, 2, '0')}`)
      setAllowedEntry(false)
    })
  }

  if (timer) clearInterval(timer)
  if (!allowedEntry){
    timer = setInterval(getTime, 1000)
  }
}

function onComponentDidUnmount(){
  if (timer) clearInterval(timer)
}

function modify(){
  return {
    initialValues: Object.assign(DEFAULT_FORM_VALUES, fetchFormValues())
  }
}

function Loading(){
  return (
    <p style={ { textAlign: 'center' } }>Loading...</p>
  )
}

export default compose(
  getSet('timeRemaining'),
  modifyProps(modify),
  connect(mapStateToProps, mapDispatchToProps),
  onMount(onComponentDidMount),
  waitFor('isLoaded', Loading),
  onUnmount(onComponentDidUnmount),
)(Reservation)
