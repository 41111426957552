import * as firebase from 'firebase'
import { logException } from 'sentry'
import { DB_SALT } from 'config'
import {
  addIdsToNestedObject,
  fetchFormValues,
} from 'utils'

const {
  FIREBASE_DATABASE_URL,
  FIREBASE_KEY,
  FIREBASE_PROJECT_ID,
  FIREBASE_AUTH_DOMAIN,
  DB_AUTH,
  NODE_ENV,
  AUTH_DISABLED,
  RECAPTCHA_ENABLED,
} = process.env

let firebaseDb = null

const [FIREBASE_AUTH_EMAIL, FIREBASE_AUTH_PASSWORD] = window.atob(DB_AUTH).replace(DB_SALT, '').split(':')

const FIREBASE_CONFIG = {
  apiKey: FIREBASE_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
}

function initialize () {
  return firebase.initializeApp(FIREBASE_CONFIG)
}

function signInUser () {
  console.log('signing in')
  if (firebase.auth().currentUser) return
  return firebase
    .auth()
    .signInWithEmailAndPassword(FIREBASE_AUTH_EMAIL, FIREBASE_AUTH_PASSWORD)
    .then(() => {
      firebaseDb = firebase.database()
    })
    .catch(error => logException(error))
}

initialize()
if (!AUTH_DISABLED) signInUser()

// Data to be displayed on the confirmation page
function serializeConfirmationInfo (reservation) {
  return {
    id: reservation.id,
    children: reservation.children.map(child => ({
      id: child.id,
      name: child.firstName + ' ' + child.lastName
    }))
  }
}

export function submitReservation () {
  if (RECAPTCHA_ENABLED) {
    const key = window.grecaptcha.getResponse()
    if (key.length === 0) return Promise.reject(true)
  }
  const params = fetchFormValues()
  const submissionsRef = firebase.database().ref('submissions')
  const timestamp = firebase.database.ServerValue.TIMESTAMP
  const reservation = addIdsToNestedObject(params)
  return submissionsRef.push({
    timestamp,
    ...reservation,
  })
  .then(() => {
    const confirmationInfo = serializeConfirmationInfo(reservation)
    return Promise.resolve(confirmationInfo)
  })
  .catch((e) => {
    if (NODE_ENV !== 'production') logException(e)
    return Promise.reject({ error: e })
  })
}

export function saveReservation () {
  return Promise.resolve(true)
}

export function closeFirebaseConnection () {
  if (firebase.auth().currentUser) firebase.auth().signOut()
  if (firebaseDb) return firebaseDb.goOffline()
}
