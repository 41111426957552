import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as routerActions from 'connected-react-router'
import logoImg from 'images/logo.svg'
import contactButton from 'images/Contact-Button.png'
import printImg from 'images/print.png'
import { 
  redirectUnless,
  connectQuery,
} from 'utils'
import { selectors } from '../reducer'

const propTypes = {
  confirmationInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
}

const defaultProps = {}

function Confirmation ({ confirmationInfo }) {
  return (
    <div>
      <header>
        <img src={ logoImg } alt="Empower Illinois" className="logo" />
        <div className="text-block">
          <h1>Tax Credit Scholarship Reservation</h1>
          <p className="es"><i>(Reservación para la beca)</i></p>
        </div>
      </header>
      <div className="progress-bar">
        <ol>
          <li className="active"><span>1</span></li>
          <li className="active"><span>2</span></li>
          <li className="active"><span>3</span></li>
          <div className="progress-line full"></div>
        </ol>
      </div>
      <div className="content-block-container">
        <h2>Thank you!</h2>
        <p className="es"><i>(¡Gracias!).</i></p>

        <p className="center"><strong>Your reservation form has been submitted. You will receive a confirmation email within 48 hours. There will be a second email inviting you to the application process with additional instructions.</strong></p>
        <p className="es"><i>(Su formulario de reserva ha sido enviado. Recibirá un correo electrónico de confirmación dentro de las 48 horas. Habrá un segundo correo electrónico con instrucciones adiciónales que se le va enviar, invitándole al proceso de aplicación.)</i></p>

        <p><strong>Reservation Code:</strong></p>
        <p className="es left"><i>(Número de confirmación:)</i></p>
        <h2 className="code">{ confirmationInfo.id }</h2>

        <p><strong>Student identification numbers:</strong></p>
        <p className="es left"><i>(Identificacións de los estudiantes:)</i></p>

        {
          confirmationInfo.children.map(({ name, id }) => 
            <h2 className="code" key={ id }> { name }: <br /> { id }</h2>
          )
        }

        <p><strong>Next Steps:</strong></p>
        <ol>
          <li>
            <p>You must still complete the second part of the process, the full application, in your <a target="_blank" rel="noopener noreferrer" href="http://app.empowerillinois.org/">EmpowerXChange student portal</a>. Within the next few days, you will receive an invitation to complete the second step of the Empower Illinois tax credit scholarship application at this email address. Your reservation time stamp already recorded your place in line, so please carefully and accurately complete the application.</p>
            <p className="es left"><i>(Aún debe completar la segunda parte del proceso, la solicitud completa, <a target="_blank" rel="noopener noreferrer" href="http://app.empowerillinois.org/">en su portal de estudiantes EmpowerXChange</a>. En los próximos días, recibirá una invitación para completar el segundo paso de la solicitud de beca de crédito fiscal con Empower Illinois en esta dirección de correo electrónico. Su estampa de tiempo de reserva ya registró su lugar en línea, así que complete la solicitud con cuidado y precisión.)</i></p>
          </li>
          <li>
            <p>You can upload all required documents and complete the application in your student portal.</p>
            <p className="es left"><i>(Podrá cargar todos los documentos requeridos y completar la solicitud en su portal de estudiantes.)</i></p>
          </li>
        </ol>

        <p><a className="center" href="javascript:window.print()"><img src={ printImg } alt="Print this Page (Imprime esta página)" className="contact-button" /></a></p>

        <p><strong>NOTE: DO NOT LOSE THESE NUMBERS.</strong> Please print this page or write down your confirmation codes for future reference.</p>
        <p className="es left"><i>(<strong>NOTA: NO PIERDA ESTOS NÚMEROS.</strong> Imprima esta página o escriba los números de confirmación para futura referencia.)</i></p>

        <a className="center" href="https://p2a.co/irR0HuH" target="_blank" rel="noopener noreferrer"><img src={ contactButton } alt="Contact Your Lawmaker (Contacte su legislador)" className="contact-button" /></a>
        
        <p>The Tax Credit Scholarship Program gives our kids a bright future, and your local lawmakers control the program's fate. Your local state senator and state representative in Springfield decide whether to protect and expand the program. Your local lawmakers decide whether to end the program. Let them know how much tax credit scholarships mean to you and your family by asking your local lawmakers to fight for the future of Illinois. Raise your voice! It's for your kids and their future.</p>
        <p className="es left">
          <i>(El programa fiscal de becas de crédito les da a nuestros hijos un futuro mejor, y sus legisladores locales controlan el destino del programa. Su senador estatal local y su representante estatal en Springfield deciden si protegen y expanden el programa. Sus legisladores locales deciden si finalizan el programa. Hágales saber cuánto significan las becas de crédito fiscal para usted y su familia pidiéndoles a los legisladores locales que luchen por el futuro de Illinois. ¡Haz oír tu voz! Esto es para sus hijos y su futuro.)</i>
        </p>
      </div>
    </div>
  )
}

Confirmation.propTypes = propTypes

Confirmation.defaultProps = defaultProps

function mapStateToProps (state) {
  return {
    allowedEntry: selectors.allowedEntry(state),
  }
}

const mapDispatchToProps = {
  push: routerActions.push,
}

export default compose(
  connectQuery('confirmationInfo'),
  connect(mapStateToProps, mapDispatchToProps),
  redirectUnless('confirmationInfo'),
  redirectUnless('allowedEntry', '/'),
)(Confirmation)
