import { logException } from 'sentry'
import axios from 'axios';

const COUNTDOWN_SERVER_URL = process.env.COUNTDOWN_SERVER_URL
let countdownOverride = process.env.COUNTDOWN

export function getSecondsRemaining() {
  return new Promise((resolve, reject) => {
    if (countdownOverride && countdownOverride > 0) {
      countdownOverride -= 1
      return resolve(countdownOverride)
    }
    
    return axios.get(COUNTDOWN_SERVER_URL)
    .then(resp => resolve(Number(resp.data.t)))
    .catch(err => {
      logException(err)
      reject(err)
    })
  })
}
